import React from 'react';
import { Row } from 'react-bootstrap';
import Footer from '../../Components/Footer';
import images from '../../utils/images';
import './style.css';

function Introduction() {
  return (
    <div className="container-fluid p-0">
      <div className="introduction-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-11">
              <p className="motility-disorders">
                People with bowel motility disorders such as IBS, constipation, diarrhea have a
                higher propensity of having abdominal symptoms. People without IBS can also
                sometimes have these symptoms especially if taking acid reducing medications.
              </p>
              <Row>
                <div className="col-12 col-sm-6 col-md-6 col-xl-6">
                  <p className="motility-disorders">
                    The number and loudness of the bowel sounds are calculated to show if we have
                    less or more bacteria in the small intestine. Before measuring the sounds of the
                    belly, the app also asks questions that tell the app about your symptoms. Using
                    the symptom score and the sound score the app will give to the final assessment
                    of the state of your GI tract. The GutC app will also allow you to find out if
                    you are sensitive to certain foods.
                    <br />
                    The GutC app will give you your: <br /> <br />
                  </p>
                  <div>
                    <ul className="score-main">
                      <li className="distress-score-main">
                        <span className="distress-score">
                          Gastrointestinal Distress Score (GIDS)
                          <span className="distress-score-inner">your symptom score</span>
                        </span>
                      </li>
                      <li className="gutc-score-main">
                        <span className="gutc-score"> GutC score</span> which will be a color and a
                        number, calculated based on your GIDS and your Bowel Sound Analysis (BSA).
                      </li>
                      <li>
                        <span className="bowel-sound">Bowel Sound Analysis (BSA)</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-2 mt-3">
                  <img
                    className=""
                    src={images.gidsScreen}
                    height={347}
                    width={160}
                    alt="appleStore"
                  />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-2 mt-3">
                  <img src={images.recordingScreen} height={347} width={160} />
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-2 mt-3">
                  <img src={images.gutcScreen} height={347} width={160} />
                </div>
              </Row>
              <Row>
                <div className="col-12 col-md-8">
                  <div className="subtitleMedical">
                    <p className="subtitleParagraph">
                      THE GUTC APP IS NOT A SUBSTITUTE FOR MEDICAL CARE. THE APP DOES NOT PROVIDE
                      MEDICAL ADVICE AND DOES NOT CLAIM TO DIAGNOSE OR TREAT YOUR MEDICAL CONDITION.
                      YOUR HEALTH CARE PROVIDER WILL PROVIDE YOU MEDICAL ADVICE.
                    </p>
                  </div>
                </div>
              </Row>
            </div>
            {/* <div className="absoulte-screens">
              <img className="first-screen-img" src={images.gidsScreen} height={347} width={160} alt='appleStore' />
              <img src={images.recordingScreen} height={347} width={160} />
              <img src={images.gutcScreen} height={347} width={160} />
            </div> */}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Introduction;
