import React from 'react';
import { Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Footer from '../../Components/Footer';
import './style.css';

function Explanations() {
  return (
    <div className="container-fluid p-0">
      <div className="explanation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="endoLogic-explanation-text">
                Before measuring the sounds of the belly, the app also asks questions that tell the
                app about your symptoms. Using the symptom score and the sound score the app will
                give to the final calculation on the state of your GI tract. The GutC app will give
                you your symptom score,
                <span style={{ color: '#0D5565', fontWeight: 600 }}>
                  {' '}
                  Gastrointestinal Distress Score (GIDS){' '}
                </span>
                and the <span style={{ color: '#0D5565', fontWeight: 600 }}> GutC score </span>
                which will be a color and a number. There are 3 scores that the GutC app will give:
              </p>
              <div className="card-wrapper">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0">
                    <Card className="custom-card">
                      <Card.Header className="heading-green-zone">Green Zone</Card.Header>
                      <Card.Body>
                        <Card.Text className="card-text">
                          Your risk for Small Intestinal Bacterial Overgrowth (SIBO) is LOW.
                          <br /> You have not consumed food that irritates your intestines.
                          <br /> You should continue with your current management of Gut Health.
                          <br /> It is recommended to keep your small intestine clean with fewer
                          bacteria by taking fiber supplements, non-stimulant laxatives such as
                          Magnesium products, Poly Ethylene Glycol 3350(PEG3350), Methylcellulose,
                          or Polycarbophil 2 times a week.
                          <br /> You may also take Bismuth-containing preparation 2 times a week if
                          you have loose stools or seek medical advice.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0">
                    <Card className="custom-card">
                      <Card.Header className="heading-yellow-zone">Yellow Zone</Card.Header>
                      <Card.Body>
                        <Card.Text className="card-text">
                          Your risk for Small Intestinal Bacterial Overgrowth(SIBO) is MODERATE.
                          <br /> Or you have consumed food that slightly irritated your intestines.
                          <br /> <br />It is recommended that you stop taking probiotics, and avoid
                          prebiotics, such as fiber.
                          <br /> If having constipation, clean your small intestine by taking
                          non-stimulant laxatives such as Magnesium products, Poly Ethylene Glycol
                          3350(PEG3350), Methylcellulose, or Polycarbophil daily for 1 week then
                          rescan to make sure your score is lower or are in the GREEN zone.
                          <br /> <br /> If having diarrhea, it is recommended to take Bismuth containing
                          anti-diarrhea preparations for 1 week and rescan.
                          <br /> Please seek medical advice if you continue to be in the Yellow
                          zone.
                          <br /> When in the GREEN zone, It is recommended to keep your small
                          intestine clean with fewer bacteria by taking fiber supplements,
                          non-stimulant laxatives such as Magnesium products, Poly Ethylene Glycol
                          3350(PEG3350), Methylcellulose, or Polycarbophil 2 times a week.
                          <br /><br /> You may also take Bismuth-containing preparation 2 times a week if
                          you have loose stools or seek medical advice.<br /> <br /> Always seek immediate
                          medical advice if your symptoms are worse.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mt-2 mt-md-0">
                    <Card className="custom-card">
                      <Card.Header className="heading-red-zone">Red Zone</Card.Header>
                      <Card.Body>
                        <Card.Text className="card-text">
                          Your risk for Small Intestinal Bacterial Overgrowth(SIBO) is HIGH.
                          <br /> Or you have consumed food that highly irritated your intestine.
                          <br /> It is recommended to avoid this food.
                          <br /> <br /> It is recommended that you stop taking probiotics, and avoid
                          prebiotics, such as fiber.
                          <br /> If having constipation, clean your small intestine by taking
                          non-stimulant laxatives such as Magnesium products, Poly Ethylene Glycol
                          3350(PEG3350), Methylcellulose, or Polycarbophil daily for 3 days then
                          rescan to make sure your score is lower, or are in the Yellow or Green
                          zones.
                          <br /> If you are still in the Red zone, contact your medical provider for
                          advice.You may need an antibiotic treatment. <br /> <br />When in the GREEN zone, It is
                          recommended to keep your small intestine clean with fewer bacteria by
                          taking fiber supplements, non-stimulant laxatives such as Magnesium
                          products, Poly Ethylene Glycol 3350(PEG3350), Methylcellulose, or
                          Polycarbophil 2 times a week.<br /> <br /> You may take Bismuth-containing preparation
                          daily, as directed by the manufacturer for 3 days, then rescan.If your score is unchanged seek medical advice.
                          <br /> <br />
                          Always seek immediate medical advice if your symptoms are worse.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
              <Row>
                <div className="col-12 col-md-8 mt-5">
                  <h4 className="green-zone">The best advice is to stay in the green zone!</h4>
                  <div className="subtitleMedical-explanations">
                    <p className="subtitleParagraph">
                      THE GUTC APP IS NOT A SUBSTITUTE FOR MEDICAL CARE. THE APP DOES NOT PROVIDE
                      MEDICAL ADVICE AND DOES NOT CLAIM TO DIAGNOSE OR TREAT YOUR MEDICAL CONDITION.
                      YOUR HEALTH CARE PROVIDER WILL PROVIDE YOU MEDICAL ADVICE.
                    </p>
                  </div>
                </div>
              </Row>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Explanations;
