import React from 'react';
import { Row } from 'react-bootstrap';
import Footer from '../../Components/Footer';
import images from '../../utils/images';
import './style.css';

function BasicInfo() {
  return (
    <div className="container-fluid p-0">
      <div className="introduction-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-11 mt-4">
              <p className="gastrointestinal-tract ">
                The human gastrointestinal tract is complex system of about 30 feet long tube from
                the mouth (entrance) to the anus (exit). It has its own nervous system called the
                Enteric Nervous System (ENS) that allows you to move the food through the tube like
                a conveyor belt. The movement of the GI tract besides the nerves is also affected by
                multiple chemical (hormones, etc..) that work together to move the food at a certain
                speed.
              </p>
              <Row>
                <div className="col-9">
                  <p className="gastrointestinal-tract">
                    Attached to the GI tract are 2 organs, the liver and the pancreas. The liver
                    processes the chemicals, proteins, fats and carbohydrates before they can be
                    sent to the rest of the body. The pancreas makes enzymes to help us digest the
                    food and also hormones that control the level of glucose in the blood. The GI
                    tract has separate segments with sphincter (doors) that keep the segments
                    separate. The mouth is the entrance to the tube where the food gets chewed and
                    passes to the esophagus (food pipe) which passes the food to the stomach which
                    churns, tenderizes, cleans by killing the bacteria in the food with acid and
                    enzymes and passes it to the small intestine. The small intestine is where the
                    main action of digestion takes place. Using bile (from the liver) and enzymes
                    (from the pancreas) the food is broken down to smallest molecules that are sent
                    to the liver for further processing.
                  </p>
                </div>
              </Row>

              <Row>
                <div className="col-12 col-md-8">
                  <p className="gastrointestinal-tract">
                    Attached to the GI tract are 2 organs, the liver and the pancreas. The liver
                    processes the chemicals, proteins, fats and carbohydrates before they can be
                    sent to the rest of the body. The pancreas makes enzymes to help us digest the
                    food and also hormones that control the level of glucose in the blood. The GI
                    tract has separate segments with sphincter (doors) that keep the segments
                    separate. The mouth is the entrance to the tube where the food gets chewed and
                    passes to the esophagus (food pipe) which passes the food to the stomach which
                    churns, tenderizes, cleans by killing the bacteria in the food with acid and
                    enzymes and passes it to the small intestine. The small intestine is where the
                    main action of digestion takes place. Using bile (from the liver) and enzymes
                    (from the pancreas) the food is broken down to smallest molecules that are sent
                    to the liver for further processing.
                  </p>
                </div>
                <div className="col-12 col-md-8">
                  <div className="subtitleMedical-info">
                    <p className="subtitleParagraph">
                      THE GUTC APP IS NOT A SUBSTITUTE FOR MEDICAL CARE. THE APP DOES NOT PROVIDE
                      MEDICAL ADVICE AND DOES NOT CLAIM TO DIAGNOSE OR TREAT YOUR MEDICAL CONDITION.
                      YOUR HEALTH CARE PROVIDER WILL PROVIDE YOU MEDICAL ADVICE.
                    </p>
                  </div>
                </div>
              </Row>
            </div>
            <div className="vector-img">
              <img src={images.vectorImg} height={488} width={572} alt="vector" />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default BasicInfo;
