const images = {
    logo: require("../../src/assets/images/logo.png"),
    appleStore:require("../../src/assets/images/appleStore.png"),
    googlePlay:require("../../src/assets/images/googlePlay.png"),
    recordingScreen:require("../../src/assets/images/recordingScreen.png"),
    gutcScreen:require("../../src/assets/images/gutCScreen.png"),
    gidsScreen:require("../../src/assets/images/gidsScreen.png"),
    vectorImg:require("../../src/assets/images/vectorImg.png"),
  }
  
  export default images
  