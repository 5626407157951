import React from 'react';
import './style.css';
import Footer from '../../Components/Footer';

function ContactUs() {
  return (
    <div className="container-fluid p-0">
      <div className="contact-us-main-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7">
              <h1 className="heading">Contact Information</h1>
              <div className="mt-5">
                <p className="address">1200 Route 46 West, Suite 100, Clifton NJ 07013</p>
                <a className="email-customer-service" href="#">
                  customerservice@endologicusa.com
                </a>
              </div>
              <div className="contact-form-wrapper">
                <div className="contact-form  flex-wrap flex-md-nowrap">
                  <div className="input-group">
                    <label>First Name</label>
                    <input />
                  </div>
                  <div className="input-group">
                    <label>Last Name</label>
                    <input />
                  </div>
                </div>
                <div className="contact-form  flex-wrap flex-md-nowrap">
                  <div className="input-group">
                    <label>Email</label>
                    <input />
                  </div>
                  <div className="input-group">
                    <label>Phone Number</label>
                    <input />
                  </div>
                </div>
                <div className="contact-form">
                  <div className="input-group">
                    <label>Message</label>
                    <textarea></textarea>
                  </div>
                </div>
                <div className="submit-button">
                  <button className="submit-btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
