import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import NavBar from './Components/NavBar';
import AboutUs from './Pages/AboutUs';
import BasicInfo from './Pages/BasicInfo';
import ContactUs from './Pages/ContactUs';
import Explanations from './Pages/Explanations';
import Home from './Pages/Home';
import Introduction from './Pages/Introduction';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermAndConditions from './Pages/TermAndConditions';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route exact path="/" Component={Home} />
        <Route path="/introduction" Component={Introduction} />
        <Route path="/info" Component={BasicInfo} />
        <Route path="/explanations" Component={Explanations} />
        <Route path="/contact-us" Component={ContactUs} />
        <Route path="/about-us" Component={AboutUs} />
        <Route path="/term-and-conditions" Component={TermAndConditions} />
        <Route path="/privacy-policy" Component={PrivacyPolicy} />
      </Routes>
    </Router>
  );
}

export default App;
