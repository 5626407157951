import React from 'react';
import Footer from '../../Components/Footer';
import './style.css';

function TermAndConditions() {
  return (
    <div className="container-fluid p-0">
      <div className="term-and-conditions-main">
        <div className="container">
          <div className="row">
            <div className="term-and-condition-body">
              <h1 className="term-condition-heading">Terms and Conditions</h1>
              <p className="term-condition-text">
                To use our GutC, bowel sound check application, you agree:
                <br />
                <br /> You are 18 years and above in age. If you are a minor, you need to have a
                parent or legal guardian set up the GutC, bowel sound check account.
                <br />
                <br /> “I hereby acknowledge that this electronic application with its text, images,
                audio and/or video is protected by copyright and other laws and remains the
                intellectual property of EndoLogic LLC, and I hereby acknowledge that by using this
                application I shall use this material for my own personal and archival use only and
                shall not use this video or any portions of this video for any commercial or
                promotional purpose whatsoever.
                <br />
                <br /> I understand that I cannot create websites, webpages, or social media posts
                with the GutC name, or post the logo.
                <br />
                <br /> I further acknowledge that I shall not otherwise disseminate or attempt to
                disseminate more than a short excerpt of 1-3 sentences of application material
                through any medium whatsoever, including, without limitation, any websites,
                YouTube.com, Facebook, Twitter, or any other social media, media, internet or other
                outlet.”
                <br />
              </p>
              <h4 className="site-transections">
                Site Transactions and User Comments, Feedback, and Other Submissions
              </h4>
              <p className="term-condition-text">
                By making a purchase, you agree that GutC or EndoLogic, LLC may contact you via
                email to invite you to review the GutC application to collect your feedback, help
                our customers, and improve the application. If, at our request, you send certain
                specific submissions (for example, contest entries, customer reviews or photographs)
                or without a request from us you send creative ideas, suggestions, customer reviews,
                photographs, proposals, plans, or other materials, whether online, by email, by
                postal mail, or otherwise (collectively, “Comments”), you agree that GutC or
                EndoLogic, LLC may, at any time, without restriction, edit, copy, publish,
                distribute, translate, and otherwise use in any medium any Comments that you forward
                to GutC or EndoLogic, LLC. GutC or EndoLogic, LLC is and shall be under no
                obligation (1) to maintain any Comments in confidence; (2) to pay compensation for
                any Comments; or (3) to respond to any Comments. Dysfunctional Labor Maneuvers has
                the right but not the obligation to monitor and edit or remove any Comments.
                <br />
                <br />
              </p>
              <p className="term-condition-text">
                You agree that your Comments will not violate any right of any third party,
                including copyright, trademark, privacy or other personal or proprietary right. You
                further agree that your Comments will not contain libelous or otherwise unlawful,
                abusive or obscene material, or contain any computer virus or other malware that
                could in any way affect the operation of the application. You may not use a false
                e-mail address, pretend to be someone other than yourself, or otherwise mislead GutC
                or EndoLogic, LLC or third parties as to the origin of any Comments. You are solely
                responsible for any Comments you make and their accuracy. GutC or EndoLogic, LLC
                takes no responsibility and assumes no liability for any Comments posted by you or
                any third party.
                <br />
                <br /> You agree that the GutC, bowel sound check application is NOT a medical
                application and does not intend to diagnose or treat you or any of your health
                condition.
                <br />
                <br /> You agree that the recommendations stated in the GutC, bowel sound check
                application is NOT intended to be medical advice.
                <br />
                <br /> You agree that the GutC application or EndoLogic, LLC are not liable for the
                status of your health condition.
                <br />
                <br /> You agree that you need to see a trained health care provider for diagnosis
                and treatment of your condition.
              </p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default TermAndConditions;
