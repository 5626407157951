import React from 'react';
import Footer from '../../Components/Footer';
import './style.css';

function PrivacyPolicy() {
  return (
    <div className="container-fluid p-0">
      <div className="privacy-section-main">
        <div className="container">
          <div className="row">
            <div className="privacy-policy-body">
              <h1 className="privacy-policy-heading">Privacy Policy</h1>
              <p className="privacy-policy-time">
                This Privacy Policy was last updated February 26, 2023
              </p>
              <p className="privacy-policy-text">
                Thank you for visiting the GutC, bowel sound check application. Your privacy is
                important to us. This privacy policy sets out how the GutC app uses and protects any
                information that you give when you use this application. The GutC application is
                committed to ensuring that your privacy is protected. This Statement of Privacy
                applies to the GutC application and EndoLogic, LLC that governs data collection and
                usage. Should we ask you to provide certain information by which you can be
                identified when using this application or its website then you can be assured that
                it will only be used in accordance with this privacy statement.
                <br /> If you have an account to use the GutC, bowel sound check application, we
                collect billing and credit card information. This information is used to complete
                the purchase transaction.
                <br />
                <br />
                The Gut C application may also collect anonymous demographic information, which is
                not unique to you, such as your: Gender, age and city, state, and country.
              </p>
              <p>We may collect the following information:</p>
              <ul>
                <li>Name</li>
                <li>Date of birth</li>
                <li>Address, only city or postal code, state, and country</li>
                <li>Email address</li>
              </ul>
              <p>
                We use web analytics programs (Adobe Marketing Cloud, Google Analytics) to collect
                information. We will not collect any personally identifiable information about you
                through these programs. What we do with the information we gather We require this
                information to understand your gastrointestinal symptoms provide you with a better
                service, and for the following reasons:
              </p>
              <ul>
                <li>Internal record keeping.</li>
                <li>We may use the information to improve the GutC application.</li>
                <li>
                  We may periodically send promotional emails about new products, special offers or
                  other information which we think you may find interesting using the email address
                  which you have provided.
                </li>
                <li>
                  From time to time, we may also use your information to contact you for market
                  research purposes. We may contact you by email or a text message to your device.
                  We will not disclose your personally identifiable information to any person or
                  company except where you have given us permission to do so or if the law requires.
                </li>
              </ul>
              <h5 className="middle-heading">Sharing Information with Third Parties</h5>

              <p>
                GutC or EndoLogic, LLC does not sell, rent or lease its customer lists to third
                parties. We may share data with trusted partners to help perform statistical
                analysis, send you email or postal mail, provide customer support, or arrange for
                business if you agree. All such third parties are prohibited from using your
                personal information and they are required to maintain the confidentiality of your
                information.
              </p>
              <h5 className="middle-heading">Right to Deletion</h5>
              <p>
                Subject to certain exceptions set out below, on receipt of a verifiable request from
                you, we will: <br /> <br />
                Please note that we may not be able to comply with requests to delete your personal
                information if it is necessary to:
              </p>
              <ul>
                <li>Delete your personal information from our records; and</li>
                <li>
                  Complete the transaction for which the personal information was collected, fulfill
                  the terms in accordance with federal law, provide a good or service requested by
                  you, or reasonably anticipated within the context of our ongoing business
                  relationship with you, or otherwise perform a contract between you and us.
                </li>
                <li>
                  Detect security incidents, protect against malicious, deceptive, fraudulent, or
                  illegal activity; or prosecute those responsible for that activity.
                </li>
                <li>
                  Debug to identify and repair errors that impair existing intended functionality.
                </li>
              </ul>
              <h5 className="middle-heading">Security</h5>
              <p>
                We are committed to ensuring that your information is secure. In order to prevent
                unauthorized access or disclosure, we have put in place suitable physical,
                electronic and managerial procedures to safeguard and secure the information we
                collect online.
                <br />
                <br />
                Changes and Updates to This Policy:
                <br />
                <br />
                We reserve the right to change the information, services, features and policies at
                any time. We will post changes to this and any other policies on this application in
                writing. Contact Information:
                <br />
                If you have any questions regarding our privacy policy, please contact us via our
                contact form at
                <br />
                <br />
                www.endologicusa.com
                <br />
                <br />
                or by writing to us at
                <br />
                <br />
                customerservice@endologicusa.com
              </p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
