import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

function Footer() {
  return (
    <div>
      <ul className="term-conditions-privacy">
        <li>
          <Link className="term-conditions" to="/term-and-conditions"> Terms and Conditions</Link>
        </li>
        <li>
   
          <Link className="privacy-policy" to="/privacy-policy">
            Privacy Policy</Link>
        </li>
      </ul>
    </div>
  );
}

export default Footer;
