import React from 'react';
import Footer from '../../Components/Footer';
import images from '../../utils/images';
import './style.css';

function Home() {
  return (
    <div className="container-fluid p-0">
      <div className="main-section">
        <div className="container">
          <div className="row">
            <div className="col-9 col-sm-8 col-md-8">
              <h1 className="heading">A quick analysis of your bowel sounds!</h1>
              <p className="cutc-main-paragraph">
                The GutC app is a sophisticated analysis of your bowel sounds using the microphone
                of your smartphone. Developed by EndoLogic a pharmaceutical innovation company, a US
                trained Gastroenterologist specializing in
                <span style={{ color: '#0D5565' }}> Irritable Bowel Syndrome (IBS)</span> and other
                GI motility disorders. GutC is a patent pending application, first on the market
                that measures your bowel sounds and compares it with your gastrointestinal symptoms.
              </p>
              <p className="cutc-second-paragraph">
                A "noisy belly" especially if you have IBS usually means that you are having a
                flare-up with bloating, gas, cramping, abdominal pain, diarrhea or constipation.
                Most GI doctors will diagnose it as small bowel bacterial overgrowth (SIBO). GutC
                will analyze your bowel sounds and your symptoms and will let you know in less than
                5 minutes if you are in the
                <span style={{ color: '#4CD964', fontWeight: 'bold' }}> Green zone</span> (good),
                <span style={{ color: '#FFD000', fontWeight: 'bold' }}> Yellow zone</span> (not so
                good) or the <span style={{ color: '#E03839', fontWeight: 'bold' }}> Red zone </span>
                (bad). GutC will allow you to find out if you are sensitive to any foods!
                <br />
                <br />
                Please try it for your belly's sake!
              </p>
              <div className="apple-google-btn">
                <img
                  className="apple-btn"
                  src={images.appleStore}
                  height="53"
                  width={191}
                  alt="appleStore"
                />
                <img
                  className="google-btn"
                  src={images.googlePlay}
                  height="53"
                  width={191}
                  alt="googlePlay"
                />
              </div>
              <div className="subtitleMedical">
                <p className="subtitleParagraph">
                  THE GUTC APP IS NOT A SUBSTITUTE FOR MEDICAL CARE. THE APP DOES NOT PROVIDE
                  MEDICAL ADVICE AND DOES NOT CLAIM TO DIAGNOSE OR TREAT YOUR MEDICAL CONDITION.
                  YOUR HEALTH CARE PROVIDER WILL PROVIDE YOU MEDICAL ADVICE.
                </p>
              </div>
            </div>
            <div className="col-3 col-sm-4 col-md-4" style={{ position: 'relative' }}>
              <img
                className="body-logo"
                src={images.logo}
                height="395"
                width={395}
                alt="body-logo"
              />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Home;
