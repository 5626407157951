import React from 'react';
import Footer from '../../Components/Footer';
import './style.css';

function AboutUs() {
  return (
    <div className="container-fluid p-0">
      <div className="about-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10">
              <p className="endoLogic-about-text">
                GutC has been developed EndoLogic, a pharmaceutical development company that finds
                innovations for digestive disorder. More information about the GutC app and our
                other products are provided on our website, <a className="endologic-web-link" href="#">www.endologicusa.com </a>
              </p>
              <p className="endoLogic-about-second-paragraph">
                A team of scientists headed by a US trained gastroenterologist, Zamir S. Brelvi MD,
                PhD, who has more than 20 years experience in treating patients with Irritable Bowel
                Syndrome and other gastrointestinal motility disorders. Dr Brelvi with his team of
                scientists have worked for more than 5 years to develop this application.
              </p>
            </div>
            <div className="col-12 col-md-8">
              <div className="subtitleMedical-about">
                <p className="subtitleParagraph">
                  THE GUTC APP IS NOT A SUBSTITUTE FOR MEDICAL CARE. THE APP DOES NOT PROVIDE
                  MEDICAL ADVICE AND DOES NOT CLAIM TO DIAGNOSE OR TREAT YOUR MEDICAL CONDITION.
                  YOUR HEALTH CARE PROVIDER WILL PROVIDE YOU MEDICAL ADVICE.
                </p>
              </div>
            </div>
          </div>
          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
